<template>
  <el-row type="flex" justify="space-around" align="middle" class="tela-dividida outside">
    <!-- LADO ESQUERDO DA TELA -->
    <el-col class="hidden-md-and-down" :span="12">
      <svg class="ilustracao">
        <!-- Isso aqui é extravagante, mas para encontrar o caminho do svg pós compilação do webpack é necessário  -->
        <use :href="`${require('@/assets/images/senha-ilustracao.svg')}#illustration`" />
      </svg>
    </el-col>

    <!-- LADO DIREITO -->
    <el-col :sm="24" :md="16" :lg="12">
      <div class="info-box">
        <!-- Cabeçalho redefinição de senha -->
        <div class="info-box__header">
          <div id="logo-contabilidade"></div>
          <section>
            <h1 class="titulo txt-primario">Renovar login</h1>
            <p class="txt-secundario">Para a sua segurança, para acessar esse recurso você precisa reconfirmar a sua senha</p>
          </section>
        </div>

        <!-- Formulário de senha -->
        <el-form :rules="rules" :model="input" ref="form-renovar" v-loading="loading">
          <!-- Senha senha -->
          <el-form-item prop="senha" label="Senha">
            <el-input v-model="input.senha" :type="senhaVisivel ? 'text' : 'password'" required></el-input>
            <span @click="() => {senhaVisivel = !senhaVisivel;}" class="visualizar-senha">
              <i v-if="!senhaVisivel" class="fas fa-eye"></i>
              <i v-else class="fas fa-eye-slash"></i>
            </span>
          </el-form-item>

          <!-- Btn -->
          <el-form-item>
            <el-button type="primary" @click="renovar">Renovar</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-col>
  </el-row>
</template>

<script>
/** @typedef {import('vue-router/types/router').Route} Route */
/** @typedef {import('vue-router/types/router').Location} Location */

import '@/assets/css/views-nao-autenticadas.css'
import AuthService from "../../services/AuthService.js";
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'renovarToken',
  props: ["nextPage"],
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      loading: false,
      senhaVisivel: false,
      input: {
        senha: '',
      },
      rules: {},
    }
  },
  methods: {
    renovar() {
      this.$refs['form-renovar'].validate(async (valid) => {
        // Caso a verificação dos inputs acusou erro ou
        // Se o recaptcha não foi verificado: encerra o
        // método e manda mensagem de erro
        if (!valid) return this.$message({ message: 'Alguns campos não foram preenchidos corretamente.', type: 'warning' })

        // Ativa o loading
        this.loading = true

        // Gera o objeto de envio
        const obj = {
          senha: this.input.senha,
        }

        // Tenta...
        try {
          // Executa a requisição
          const r = await AuthService.usuarioTokenRenovar(obj)

          // Se houver qualquer valor diferente de 200: erro
          if ((r.status + "").charAt(0) !== '2')  throw new Error();

          // Caso contrário: mostra uma mensagem de sucesso
          this.$message({ message: 'Login renovado com sucesso!', type: 'success' })

          // Se o nextPage não tiver valor, vai para o login
          if (!this.nextPage) this.$router.push('/')

          // Caso contrário...
          // Recupera o route com type
          /** @type {Route} */
          const np = this.nextPage

          // Cria um obj com o type Location
          /** @type {Location} */
          let l = {}

          // Adiciona informações
          l.name = np.name
          l.path = np.path
          l.hash = np.hash
          l.query = np.query
          l.params = np.params
          l.append = false
          l.replace = false

          // Troca de página
          this.$router.push(l)
        } catch (e) {
          // De acordo com resultado, mostra uma mensagem
          switch (e?.response?.status) {
            // Unprocessable Entity
            case 422: this.$message({ message: e.response.headers['x-reason'], type: 'error' }); break;

            // Qualquer outra reponse
            default: this.$message({ message: 'Um erro inesperado de servidor ocorreu.', type: 'error' })
          }
        } finally {
          // Ao finalizar...
          // Retira o loading
          this.loading = false
        }
      })
    }
  },
}
</script>

<style scoped>
>>> .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
}
>>> .el-form-item__content{
  text-align: left;
}
section{
  text-align: left;
}
</style>